import React, { Component, Fragment } from 'react';

import { CAREER_PLAN_STATUSES } from '@learned/constants';
import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import unescape from 'lodash/unescape';
import uniq from 'lodash/uniq';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import '~/components/Company/Company.scss';
import AddPeopleModal from '~/components/AddPeopleModal';
import DashboardCard from '~/components/DashboardCard';
import DeleteModal from '~/components/DeleteModal';
import AddActivitiesFromCompanyLibraryModal from '~/components/Modals/AddActivitiesFromCompanyLibraryModal';
import OptionsMenu from '~/components/OptionsMenu';
import OverviewHeading from '~/components/OverviewHeading';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import SvgIcon from '~/components/SvgIcon';
import Tabs from '~/components/Tabs';
import AvatarCard from '~/components/UI/AvatarCard';
import TableCard from '~/components/UI/TableCard';
import BaseLayout from '~/layouts/BaseLayout';
import InfoTab from '~/pages/CompanyJobProfile/Tabs/InfoTab';
import RoleLearningTab from '~/pages/CompanyJobProfile/Tabs/RoleLearningTab';
import NewJobProfileModal from '~/pages/CompanyJobProfiles/NewJobProfileModal';

import RolePlaceholderImg from '~/assets/role-card-placeholder.png';
import trash from '~/assets/trash.svg';

import { WORK_EXPERIENCE_LEVELS, EDUCATION_LEVELS } from '~/constants';
import routes from '~/constants/routes';
import { checkModuleLearning } from '~/selectors/baseGetters';
import { getCareerPlans, createCareerPlan, deleteCareerPlan } from '~/services/careerPlans';
import {
  getJobProfile,
  deleteJobProfiles,
  updateJobProfile,
  updateSkillsOfJobProfile,
} from '~/services/jobProfiles';
import { setJobProfile, removeJobProfiles } from '~/store/jobProfiles/actions';
import { COLOR_PALETTE } from '~/styles';
import disableActionOnLoading from '~/utils/disableActionOnLoading';

const ReviewTabs = styled(Tabs)`
  padding: 0 24px;
`;

const CoverImage = styled.div`
  height: 190px;
  background: url('${(props) => props.src}');
  border-radius: 6px;
  background-repeat: no-repeat;
  background-size: cover;
`;

const StyledDashboardCard = styled(DashboardCard)`
  padding: 0 0 16px;
`;

const JobProfileRow = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const JobProfileNameRow = styled(JobProfileRow)`
  margin: 24px;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.4;
  color: #000000;
`;

const PeopleWrapper = styled.div``;

const PeopleLabel = styled.div`
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.45px;
  color: #000000;
`;

const PeopleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 72px;
  padding: 0 16px;
`;

const TABS_ENUM = {
  info: 'info',
  people: 'people',
  learning: 'learning',
};

const TABS = (numberOfPeople, numberOfActivities, isModuleLearningEnabled) =>
  [
    {
      label: t`Info`,
      key: TABS_ENUM.info,
    },
    {
      label: t`Members (${numberOfPeople})`,
      key: TABS_ENUM.people,
    },
    isModuleLearningEnabled && {
      label: t`Learning (${numberOfActivities})`,
      key: TABS_ENUM.learning,
    },
  ].filter((i) => i);

class CompanyJobProfile extends Component {
  constructor() {
    super();

    const currentTab = window.location.hash.substr(1) || TABS()[0].key;

    this.state = {
      name: '',
      description: '',
      jobProfile: {},
      careerPlans: {},
      isShowAddSkillModal: false,
      isShowAddPeopleModal: false,
      isShowJobProfileModal: false,
      isShowAddActivitiesModal: false,
      showDeleteModal: false,
      loadingJobProfile: true,
      loadingCareerPlans: true,
      searchValue: '',
      currentTab,
    };
  }

  componentDidMount = () => {
    const { match } = this.props;
    this.retrieveJobProfile(match.params.roleId);
    this.retrieveCareerPlans(match.params.roleId);
  };

  retrieveJobProfile = (jobProfileId) => {
    const { dispatch } = this.props;
    getJobProfile(jobProfileId, ['skills']).then(async (jobProfile) => {
      this.setState({
        loadingJobProfile: false,
        jobProfile,
        name: unescape(jobProfile.name || ''),
        description: jobProfile.description || '',
      });
      await dispatch(setJobProfile(jobProfile));
    });
  };

  retrieveCareerPlans = (jobProfileId) => {
    getCareerPlans({ jobProfile: jobProfileId, status: CAREER_PLAN_STATUSES.CURRENT }).then(
      (careerPlans) => {
        this.setState({
          loadingCareerPlans: false,
          careerPlans,
        });
      },
    );
  };

  createCareerPlan = async (userId) => {
    const { jobProfile } = this.state;
    await createCareerPlan(jobProfile.id, CAREER_PLAN_STATUSES.CURRENT, userId).then(
      (newCareerPlan) => {
        this.setState((prevState) => ({
          ...prevState,
          careerPlans: { ...prevState.careerPlans, [newCareerPlan.id]: newCareerPlan },
        }));
      },
    );
  };

  deleteCareerPlan = (userId) => {
    const { jobProfile, careerPlans } = this.state;
    const careerPlan = Object.values(careerPlans).find((plan) => {
      return (
        plan.createdFor === userId &&
        plan.status === CAREER_PLAN_STATUSES.CURRENT &&
        (plan.jobProfile === jobProfile.id || plan.jobProfile.id === jobProfile.id)
      );
    });
    if (!careerPlan) {
      return;
    }

    this.setState((prevState) => {
      const newState = { ...prevState };
      delete newState.careerPlans[careerPlan.id];
      return newState;
    });

    return deleteCareerPlan(careerPlan.id);
  };

  deleteJobProfiles = async () => {
    const { history, dispatch } = this.props;
    const { jobProfile } = this.state;

    history.push(routes.JOB_PROFILES_ADMIN.build());
    await deleteJobProfiles([jobProfile.id]);
    await dispatch(removeJobProfiles([jobProfile]));
  };

  onAddActivities = async (addActivities = []) => {
    const { jobProfile } = this.state;
    const { dispatch } = this.props;
    this.setState({ loadingJobProfile: true });
    const jpActivitiesId = (jobProfile?.activities || []).map((a) => a.id || a);
    const addActivitiesId = addActivities.map((a) => a.id);
    const activities = uniq([...jpActivitiesId, ...addActivitiesId]);

    this.setState((prevState) => {
      const jobProfile = Object.assign({}, prevState.jobProfile);
      jobProfile.activities = activities;
      updateJobProfile(jobProfile.id, { activities });
      dispatch(setJobProfile(jobProfile));
      return { jobProfile, loadingJobProfile: false };
    });
  };

  toggleDeleteModal = () => {
    const { jobProfile } = this.state;
    if (!isEmpty(jobProfile)) {
      this.setState({ showDeleteModal: !this.state.showDeleteModal });
    }
  };

  onShowAddPeopleModal = () => {
    this.setState({
      isShowAddPeopleModal: true,
    });
  };

  onHideAddPeopleModal = () => {
    this.setState({
      isShowAddPeopleModal: false,
    });
  };

  onShowJobProfileModal = () => {
    const { history } = this.props;
    const { jobProfiles, match } = this.props;
    let jobProfile = jobProfiles ? jobProfiles[match.params.roleId] : this.state.jobProfile;
    history.push(routes.JOB_UPDATE.build({}, { roleId: jobProfile.id, isBackPath: true }));
  };

  onHideJobProfileModal = () => {
    this.setState({
      isShowJobProfileModal: false,
    });
  };

  handleChangeTab = (key) => {
    window.location.hash = key;
    this.setState({
      currentTab: key,
    });
  };

  onUpdateSkill =
    (skill) =>
    async (_e, { level } = {}) => {
      const { loadingJobProfile, loadingCareerPlans, dispatch } = this.props;
      this.setState({ loadingJobProfile: true });

      if (disableActionOnLoading(loadingJobProfile || loadingCareerPlans)) {
        return;
      }

      this.setState((prevState) => {
        const jobProfile = Object.assign({}, prevState.jobProfile);
        jobProfile.skillsLevels[skill.id] = level;
        updateSkillsOfJobProfile(jobProfile.id, jobProfile);
        dispatch(setJobProfile(jobProfile));
        return { jobProfile, loadingJobProfile: false };
      });
    };

  onRemoveSkill = async (skill) => {
    const { loadingJobProfile, loadingCareerPlans, dispatch } = this.props;

    if (disableActionOnLoading(loadingJobProfile || loadingCareerPlans)) {
      return;
    }

    this.setState((prevState) => {
      const jobProfile = Object.assign({}, prevState.jobProfile);
      delete jobProfile.skillsLevels[skill.id];
      jobProfile.skills.splice(
        jobProfile.skills.findIndex((s) => s.id === skill.id),
        1,
      );
      updateSkillsOfJobProfile(jobProfile.id, jobProfile);
      dispatch(setJobProfile(jobProfile));
      return { jobProfile, loadingJobProfile: false };
    });
  };

  onAddPeople = async (selectedUsers) => {
    const { loadingJobProfile, loadingCareerPlans } = this.state;

    if (disableActionOnLoading(loadingJobProfile || loadingCareerPlans)) {
      return;
    }

    await Promise.all(selectedUsers.map((user) => this.createCareerPlan(user.id)));
  };

  getTabContent = () => {
    const { careerPlans, currentTab, searchValue } = this.state;
    const { jobProfiles, i18n, match, usersActive, dispatch } = this.props;
    let jobProfile = jobProfiles ? jobProfiles[match.params.roleId] : this.state.jobProfile;

    const usersWithCareerPlan = Object.values(careerPlans)
      .map((plan) => usersActive[plan.createdFor])
      .filter((u) => u);

    const education =
      !isEmpty(jobProfile) &&
      jobProfile.educationLevel &&
      EDUCATION_LEVELS[jobProfile.educationLevel].shortName(i18n);

    const experience =
      !isEmpty(jobProfile) &&
      jobProfile.careerLevel &&
      WORK_EXPERIENCE_LEVELS[jobProfile.careerLevel].shortName(i18n);

    switch (currentTab) {
      case TABS_ENUM.people:
        return (
          <PeopleWrapper>
            <TableCard
              title={<PeopleLabel>Name</PeopleLabel>}
              items={usersWithCareerPlan}
              renderRow={this.renderPeopleRow}
              textPlaceholder={i18n._(t`Which employees do you want to invite?`)}
              searchBy="firstName"
              searchBox
              noShadow
              noBorder={true}
            />
          </PeopleWrapper>
        );
      case TABS_ENUM.info:
        return (
          <InfoTab
            education={education}
            experience={experience}
            i18n={i18n}
            jobProfile={jobProfile}
            searchValue={searchValue}
            onRemoveSkill={this.onRemoveSkill}
            onUpdateSkill={this.onUpdateSkill}
          />
        );
      case TABS_ENUM.learning:
        return (
          <RoleLearningTab
            jobProfile={jobProfile}
            updateJobProfileState={(newJp) => {
              this.setState({ jobProfile: newJp });
              dispatch(setJobProfile(newJp));
            }}
          />
        );
      default:
    }
  };

  renderPeopleRow = (user) => {
    return (
      <PeopleRow key={user.id}>
        <AvatarCard userId={user.id} />
        <SvgIcon
          url={trash}
          onClick={() => this.deleteCareerPlan(user.id)}
          width="20px"
          height="20px"
          isDefaultColor
          defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
        />
      </PeopleRow>
    );
  };

  renderImage = () => {
    const { jobProfiles, match } = this.props;
    let jobProfile = jobProfiles ? jobProfiles[match.params.roleId] : this.state.jobProfile;
    const url = jobProfile.coverImage;

    return <CoverImage src={url || RolePlaceholderImg} />;
  };

  render() {
    const {
      careerPlans,
      isShowAddPeopleModal,
      isShowJobProfileModal,
      isShowAddActivitiesModal,
      name,
      loadingJobProfile,
      showDeleteModal,
      currentTab,
    } = this.state;
    const { usersActive, jobProfiles, i18n, match, isModuleLearningEnabled } = this.props;
    let jobProfile = jobProfiles ? jobProfiles[match.params.roleId] : this.state.jobProfile;

    const usersWithCareerPlan = Object.values(careerPlans)
      .map((plan) => usersActive[plan.createdFor] && plan.createdFor)
      .filter((u) => u);

    const usersWithoutJob = Object.values(usersActive).filter(
      (u) => !usersWithCareerPlan.find((u2) => u.id === u2),
    );

    const options = [
      {
        label: (i18n) => i18n._(t`Edit`),
        onClick: this.onShowJobProfileModal,
      },
      {
        label: (i18n) => i18n._(t`Assign`),
        onClick: this.onShowAddPeopleModal,
      },
      isModuleLearningEnabled && {
        label: (i18n) => i18n._(t`Add learning activity`),
        onClick: () => {
          this.setState({ isShowAddActivitiesModal: true });
        },
      },
      {
        label: (i18n) => i18n._(t`Delete`),
        onClick: this.toggleDeleteModal,
      },
    ].filter((i) => i);

    return (
      <div>
        <OverviewHeading
          title={i18n._(t`All roles`)}
          backPath={routes.JOB_PROFILES_ADMIN.build()}
          useSearch={false}
          smallWidth
        >
          <OptionsMenu options={options} loading={loadingJobProfile} />
        </OverviewHeading>
        <BaseLayout loading={loadingJobProfile} smallWidth>
          <ShowSpinnerIfLoading loading={loadingJobProfile}>
            <Fragment>
              <StyledDashboardCard>
                {!loadingJobProfile && jobProfile.coverImage && this.renderImage()}
                <JobProfileNameRow>{name}</JobProfileNameRow>
                <ReviewTabs
                  items={TABS(
                    usersWithCareerPlan.length,
                    (jobProfile.activities || []).length,
                    isModuleLearningEnabled,
                  )}
                  currentItem={currentTab}
                  isSmall
                  border
                  handleChangeTab={this.handleChangeTab}
                />
                {this.getTabContent()}
              </StyledDashboardCard>
            </Fragment>
          </ShowSpinnerIfLoading>
        </BaseLayout>

        {isShowJobProfileModal && (
          <NewJobProfileModal
            onModalClose={this.onHideJobProfileModal}
            afterSubmit={() => this.retrieveJobProfile(match.params.roleId)}
            jobProfile={jobProfile}
          />
        )}

        {showDeleteModal && (
          <DeleteModal
            title="job profile"
            content={i18n._(
              t`Are you sure you want to remove this job profile: ${jobProfile.name}`,
            )}
            onClose={this.toggleDeleteModal}
            onDelete={this.deleteJobProfiles}
          />
        )}

        {isShowAddPeopleModal && (
          <AddPeopleModal
            title={i18n._(t`Add people`)}
            users={usersWithoutJob}
            onModalClose={this.onHideAddPeopleModal}
            onSubmit={this.onAddPeople}
          />
        )}

        {isShowAddActivitiesModal && (
          <AddActivitiesFromCompanyLibraryModal
            onClose={() => {
              this.setState({ isShowAddActivitiesModal: false });
            }}
            onSubmit={this.onAddActivities}
            recommendedForRoles={[jobProfile.id]}
            hideActivities={jobProfile.activities}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    usersActive: state.users.data,
    jobProfiles: state.jobProfiles.data,
    isModuleLearningEnabled: checkModuleLearning(state),
  };
};

export default withI18n()(connect(mapStateToProps)(withRouter(CompanyJobProfile)));
