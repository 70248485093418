import React, { useEffect, useState } from 'react';

import { t, Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import unescape from 'lodash/unescape';
import styled from 'styled-components';

import { AutocompleteFilterSuggestedJobTitles } from '~/components/AutocompleteFilters';
import Editor from '~/components/Editor';
import ImageUploadCover from '~/components/ImageUploadCover';
import Select from '~/components/Select';
import { TextField } from '~/components/Text';
import Tooltip from '~/components/Tooltip';

import { EDUCATION_LEVELS, WORK_EXPERIENCE_LEVELS } from '~/constants';
import useBoolState from '~/hooks/useBoolState';
import { COLORS } from '~/styles';

const Label = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.14;
  color: #000000;
  margin-top: ${(props) => (props.isFirst ? '12px' : '40px')};
  margin-bottom: 8px;
  width: fit-content;
`;

const ImageUpload = styled(ImageUploadCover)`
  background-color: ${COLORS.BG_PAGE};
  border-radius: 6px;
`;

const Row = styled.div`
  display: flex;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  box-sizing: border-box;
  padding-right: 46px;
`;

const FilterRoleNames = styled(AutocompleteFilterSuggestedJobTitles)`
  width: 100%;
  border: 1px solid ${COLORS.BORDER} !important;
  border-radius: 3px;
  font-style: normal;
  font-stretch: 100%;

  letter-spacing: normal;
  line-height: 1.38;
  color: ${COLORS.TEXT_BLACK};
  padding: 12px 9px;
  height: 48px;
  box-sizing: border-box;
  font-size: ${(props) => (props.checkedList.length ? '16px' : '14px')};
  color: ${(props) => (props.checkedList.length ? COLORS.TEXT_BLACK : COLORS.PLACEHOLDER)};
  font-weight: ${(props) => (props.checkedList.length ? 'normal' : '100')};
`;

// Override internal tippy class to allow the width to be dinamically set
const FilterRoleNamesContainer = styled.div`
  [data-tippy-root] {
    width: calc(100% - 36px);
  }
`;

const SetupRoleCreate = ({ i18n, item }) => {
  const $uploadingImage = useBoolState(false);
  const [selectedRoleAlias, setSelectedRoleAlias] = useState([]);

  useEffect(() => {
    if (item.getAlias) {
      setSelectedRoleAlias([{ id: item.getAlias, name: item.getAlias }]);
    } else {
      setSelectedRoleAlias([]);
    }
  }, [item.getAlias]);

  const onChangeRoleName = (e) => {
    e.preventDefault();
    item.setName(e.target.value);
  };

  const onChangeRoleDescription = (value) => {
    item.setDescription(value);
  };

  const onChangeRoleWorkLevel = (level) => {
    item.setCareerLevel(level ? Number(level) : '');
  };

  const onChangeRoleEducationLevel = (level) => {
    item.setEducationLevel(level ? Number(level) : '');
  };

  const onChangeRoleImage = (url) => {
    item.setCoverImage(url);
    $uploadingImage.off();
  };

  const onSelectedRoleAliasChange = (roleAlias) => {
    // If there is already a selected value replace it, this also considers deselection
    let newRoleAlias = roleAlias;
    if (selectedRoleAlias.length) {
      newRoleAlias = roleAlias.filter((alias) => alias !== selectedRoleAlias[0]);
    }

    // Set item alias, and set name with the alias value
    if (newRoleAlias.length) {
      item.setAlias(newRoleAlias[0].name);

      // Fill name with alias only if the name is empty, to prevent roles from integrations from changing names
      if (!item.getName) {
        item.setName(newRoleAlias[0].name);
      }
    } else {
      // If alias was deselected, remove it from item
      item.setAlias('');
    }
  };

  return (
    <div>
      <div>
        <Tooltip
          tooltip={i18n._(
            t`Select a role alias to pre-fill the role name and to get the recommended skills for it.`,
          )}
          placement="top-start"
          maxWidth="300px"
        >
          <Label isFirst>
            <Trans>Alias</Trans>
          </Label>
        </Tooltip>

        <FilterRoleNamesContainer>
          <FilterRoleNames
            checkedList={selectedRoleAlias}
            onChange={onSelectedRoleAliasChange}
            placeholder={<Trans>Search role...</Trans>}
            selectWidth="100%"
            searchPlaceholder={i18n._(t`Search (insert at least 2 characters to obtain results)`)}
          />
        </FilterRoleNamesContainer>

        <Label>
          <Trans>Name*</Trans>
        </Label>
        <TextField
          className="textarea"
          value={unescape(item.getName)}
          onChange={onChangeRoleName}
          placeholder={i18n._(t`Example: Jr. consultant`)}
          rows={1}
        />
      </div>
      <div>
        <Label>
          <Trans>Image</Trans>
        </Label>
        <ImageUpload
          height="120px"
          imageUrl={item.getCoverImage}
          onUpload={onChangeRoleImage}
          onUploadStart={$uploadingImage.on}
          onUploadError={$uploadingImage.off}
        />
      </div>
      <div>
        <Label>
          <Trans>Description & requirements</Trans>*
        </Label>
        <Editor
          resize
          value={item.getDescription}
          onChange={onChangeRoleDescription}
          placeholder={i18n._(t`Explain the role here...`)}
        />
      </div>
      <Row>
        <Column>
          <Label>
            <Trans>Workexperience</Trans>*
          </Label>
          <Select
            value={item.getCareerLevel}
            emptyOption={isEmpty(item.getCareerLevel)}
            options={WORK_EXPERIENCE_LEVELS}
            onChange={onChangeRoleWorkLevel}
          />
        </Column>
        <Column>
          <Label>
            <Trans>Educational</Trans>*
          </Label>
          <Select
            value={item.getEducationLevel}
            emptyOption={isEmpty(item.getEducationLevel)}
            options={filter(EDUCATION_LEVELS, (l) => l)}
            onChange={onChangeRoleEducationLevel}
          />
        </Column>
      </Row>
    </div>
  );
};

export default withI18n()(SetupRoleCreate);
