import React, { Component, Fragment } from 'react';

import { INTEGRATIONS } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import unescape from 'lodash/unescape';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import Button from '~/components/Button';
import CleanUpRolesModal from '~/components/CleanUpRolesModal';
import DropdownButton from '~/components/DropdownButton';
import FiltersHeading from '~/components/FiltersHeading';
import HeadingNavigation from '~/components/HeadingNavigation';
import IconMenu from '~/components/IconMenu';
import ExpandMoreIcon from '~/components/Icons/ExpandMore';
import ImportRolesModal from '~/components/ImportRolesModal';
import '~/components/Company/Company.scss';
import Placeholder from '~/components/Placeholder';
import RoleRelevanciesModal from '~/components/RoleRelevanciesModal';
import SvgIcon from '~/components/SvgIcon';
import SyncRolesModal from '~/components/SyncRolesModal';
import ActionsContainer from '~/components/UI/ActionsContainer';
import TableCard, { TableRow, TableCol } from '~/components/UI/TableCard';
import { Header3 } from '~/components/UI/Typographics/headers';
import BaseLayout from '~/layouts/BaseLayout';

import RoleOptions from './components/RoleOptions.js';
import NewJobProfileModal from './NewJobProfileModal';

import RolesIcon from '~/assets/mdi-ballot.svg';

import { JOB_PROFILE_STATUSES } from '~/constants';
import { INSTRUCTIONS } from '~/constants/instructions';
import routes from '~/constants/routes';
import { checkModuleLearning } from '~/selectors/baseGetters';
import { getCompany } from '~/services/companies';
import { updateRoleRelevancies } from '~/services/companySettings';
import { getCompanyIntegrationSettings } from '~/services/integrationSettings';
import { getJobProfiles, deleteJobProfiles, copyJobProfile } from '~/services/jobProfiles';
import { updateCompanySettingsRoleRelevancies } from '~/store/companySettings/actions';
import {
  removeJobProfiles,
  setJobProfiles,
  setJobProfile,
  updateJobProfiles,
} from '~/store/jobProfiles/actions';
import { COLOR_PALETTE } from '~/styles';
import getInstructionUrl from '~/utils/getInstructionUrl';
import getModulesStatuses from '~/utils/getModulesStatuses';

const Dashboard = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 19px 0 0;
  overflow-y: inherit;
  margin: 15px 0;
`;

const JobProfileName = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  height: auto;
  line-height: 1.38;
  width: 650px;
  word-break: break-word;
  border-radius: 3px;
  padding: 0px 0px 0px 0px;
  margin-bottom: 0;
  position: relative;
`;

const IntegrationName = styled.div`
  color: ${COLOR_PALETTE.DARK_GRAY};
  height: 22px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const RowWithMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

class CompanyJobProfiles extends Component {
  constructor() {
    super();
    this.state = {
      company: {},
      integrationSettings: {},
      isShowNewJobProfileModal: false,
      isShowRenameThemeModal: false,
      isShowAddSkillModal: false,
      isShowImportRolesModal: false,
      isShowCleanUpRolesModal: false,
      isShowRoleRelevanciesModal: false,
      isShowSyncRolesModal: false,
      theme: {},
      updateTheme: {},
      loading: true,
      modulesStatuses: {},
    };
  }

  componentDidMount = () => {
    const { selectedCompany, dispatch } = this.props;

    if (selectedCompany) {
      this.updateStateCompany(selectedCompany);
    }

    getJobProfiles({ status: JOB_PROFILE_STATUSES.ACTIVE.key }).then(async (jobProfiles) => {
      await dispatch(setJobProfiles(jobProfiles));
      this.setState({ loading: false });
    });

    getCompanyIntegrationSettings().then((integrationSettings) => {
      this.setState({
        integrationSettings,
        modulesStatuses: getModulesStatuses(integrationSettings),
      });
    });
  };

  UNSAFE_componentWillReceiveProps = (_nextProps) => {
    const { selectedCompany } = this.props;
    if (selectedCompany) {
      this.updateStateCompany(selectedCompany);
    }
  };

  updateStateCompany = (companyId) => {
    getCompany(companyId).then((company) => {
      this.setState({
        company,
      });
    });
  };

  onShowNewJobProfileModal = () => {
    const { history } = this.props;
    history.push(routes.JOB_CREATE.build({}, { isBackPath: true }));
    this.setState({ isShowNewJobProfileModal: true });
  };

  onHideNewJobProfileModal = () => {
    this.setState({ isShowNewJobProfileModal: false });
  };

  goToTheme = (jobProfileId) => {
    const { history } = this.props;
    const { company } = this.state;
    if (!isEmpty(company)) {
      history.push(routes.JOB_PROFILE.build({}, { roleId: jobProfileId }));
    }
  };

  getCols = (i18n) => {
    return [
      {
        title: i18n._(t`Role`),
      },
      {
        title: i18n._(t`Created`),
        width: '200px',
      },
    ];
  };

  handleFiltersChange = (filter) => {
    this.setState({ searchFilter: filter });
  };

  deleteJobProfiles = async (jobProfilesToDelete) => {
    const { dispatch } = this.props;

    if (!isEmpty(jobProfilesToDelete)) {
      await deleteJobProfiles(jobProfilesToDelete.map((jp) => jp.id));
      await dispatch(removeJobProfiles(jobProfilesToDelete));
    }

    this.setState({ isShowCleanUpRolesModal: false });
  };

  updateJobProfiles = async (JobProfilesToUpdate) => {
    const { dispatch } = this.props;

    if (!isEmpty(JobProfilesToUpdate)) {
      this.setState({ loading: true });
      const preparedJobProfiles = [];
      JobProfilesToUpdate.forEach((t) =>
        preparedJobProfiles.push([
          t.jobProfileId,
          {
            name: t.newValue,
          },
        ]),
      );

      // update DB + redux-store
      await dispatch(updateJobProfiles(preparedJobProfiles));
    }

    this.setState({
      isShowSyncRolesModal: false,
      loading: false,
    });
  };

  duplicateItem = async (jobProfileId) => {
    const { dispatch } = this.props;
    this.setState({ loading: true });
    const newJobProfile = await copyJobProfile(jobProfileId);
    this.setState({ loading: false });
    await dispatch(setJobProfile(newJobProfile));
  };

  saveRelevancies = async (relevancies) => {
    const { selectedCompany, dispatch } = this.props;
    const newRelevancies = await updateRoleRelevancies(
      selectedCompany,
      relevancies.filter((i) => i.name),
    );
    this.setState({ isShowRoleRelevanciesModal: false });
    dispatch(updateCompanySettingsRoleRelevancies(newRelevancies));
  };

  renderJobProfile = (jobProfile, index) => {
    const { i18n } = this.props;
    const { integrationSettings } = this.state;

    const integrationValues = Object.values(integrationSettings);
    const integrationSoftware =
      jobProfile.externalSource && integrationValues.length
        ? integrationValues.find((x) => x.id === jobProfile.externalSource)
        : null;

    let menuItems = [];
    const duplicateAction = {
      label: i18n._(t`Duplicate`),
      action: () => this.duplicateItem(jobProfile.id),
    };

    if (!integrationSoftware) {
      menuItems.push(duplicateAction);
    }

    const shouldShowExternalId = integrationSoftware?.externalSoftware !== INTEGRATIONS.KOMBO;

    const jobProfileName = unescape(jobProfile.name);

    return jobProfile ? (
      <TableRow key={index} height={'auto'}>
        <TableCol height={'auto'}>
          <JobProfileName onClick={() => this.goToTheme(jobProfile.id)}>
            <Header3>
              {jobProfile.externalId && shouldShowExternalId
                ? `${jobProfileName} (${jobProfile.externalId})`
                : jobProfileName.slice(0, 100)}
            </Header3>
          </JobProfileName>
        </TableCol>
        <TableCol>
          <RowWithMenu>
            <IntegrationName>
              {integrationSoftware ? integrationSoftware.name : <Trans>Manual</Trans>}
            </IntegrationName>
            {!integrationSoftware && <IconMenu items={menuItems} />}
          </RowWithMenu>
        </TableCol>
      </TableRow>
    ) : null;
  };

  render() {
    const {
      isShowNewJobProfileModal,
      isShowImportRolesModal,
      isShowCleanUpRolesModal,
      isShowSyncRolesModal,
      isShowRoleRelevanciesModal,
      company,
      loading,
      searchFilter,
    } = this.state;
    const { i18n, jobProfiles = {}, isModuleLearningEnabled } = this.props;

    let jobProfilesFiltered = Object.values(jobProfiles)
      .filter((j) => j.status !== JOB_PROFILE_STATUSES.INACTIVE.key)
      .sort((a, b) => a.name.localeCompare(b.name));

    if (searchFilter) {
      jobProfilesFiltered = jobProfilesFiltered.filter(searchFilter);
    }

    return (
      <Fragment>
        <HeadingNavigation
          label={i18n._(t`Roles`)}
          description={i18n._(t`An overview of all roles`)}
          instructions={i18n._(t`How roles work`)}
          instructionsUrl={getInstructionUrl(INSTRUCTIONS.CREATING_ROLES)}
          actions={
            <DropdownButton
              minWidth={248}
              maxWidth={526}
              popoverRef={this.popoverRef}
              content={
                <RoleOptions
                  createRole={this.onShowNewJobProfileModal}
                  importRoles={() => this.setState({ isShowImportRolesModal: true })}
                  synchroniseRoles={() => this.setState({ isShowSyncRolesModal: true })}
                  cleanupRoles={() => this.setState({ isShowCleanUpRolesModal: true })}
                  showRoleRelevancies={() => this.setState({ isShowRoleRelevanciesModal: true })}
                  isDeleteRolesEnabled={this.state.modulesStatuses.isDeleteRolesEnabled}
                  isImportRolesEnabled={this.state.modulesStatuses.isImportRolesEnabled}
                  isSyncRolesEnabled={this.state.modulesStatuses.isSyncRolesEnabled}
                  isLearningEnabled={isModuleLearningEnabled}
                />
              }
            >
              <Button
                label={
                  <>
                    {i18n._(t`Options`)}
                    <ExpandMoreIcon fill="#fff" />
                  </>
                }
              />
            </DropdownButton>
          }
        />
        <BaseLayout>
          <ActionsContainer noBottomBorder>
            <FiltersHeading onFilterChange={this.handleFiltersChange} />
          </ActionsContainer>
          <TableCard
            cols={this.getCols(i18n)}
            title={i18n._(t`Job profiles`)}
            items={jobProfilesFiltered}
            loading={loading}
            renderRow={this.renderJobProfile}
            hideHeader
            noTopBorder
            hideSearch
            firstPlaceholder={
              <Placeholder
                Icon={() => (
                  <SvgIcon
                    url={RolesIcon}
                    width={'50px'}
                    height={'50px'}
                    isDefaultColor
                    defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
                  />
                )}
                title={i18n._(t`No roles available`)}
                subTitle={i18n._(t`There have not been created any role profiles`)}
              />
            }
          />
          <Dashboard>
            {isShowNewJobProfileModal && (
              <NewJobProfileModal onModalClose={this.onHideNewJobProfileModal} company={company} />
            )}
            {isShowImportRolesModal && (
              <ImportRolesModal onClose={() => this.setState({ isShowImportRolesModal: false })} />
            )}
            {isShowCleanUpRolesModal && (
              <CleanUpRolesModal
                onClose={() => this.setState({ isShowCleanUpRolesModal: false })}
                onSubmit={this.deleteJobProfiles}
              />
            )}
            {isShowSyncRolesModal && (
              <SyncRolesModal
                onClose={() => this.setState({ isShowSyncRolesModal: false })}
                onSubmit={this.updateJobProfiles}
              />
            )}
            {isShowRoleRelevanciesModal && (
              <RoleRelevanciesModal
                onClose={() => this.setState({ isShowRoleRelevanciesModal: false })}
                onSubmit={this.saveRelevancies}
              />
            )}
          </Dashboard>
        </BaseLayout>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedCompany: state.selected.company,
    jobProfiles: state.jobProfiles.data,
    isModuleLearningEnabled: checkModuleLearning(state),
  };
};

export default withI18n()(connect(mapStateToProps)(withRouter(CompanyJobProfiles)));
