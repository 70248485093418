import React, { useEffect, useState } from 'react';

import { INTEGRATIONS } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';

import LearnMore from '~/components/LearnMore';
import Placeholder from '~/components/Placeholder';
import SearchSelectButton from '~/components/SearchSelectButton';
import SvgIcon from '~/components/SvgIcon';
import Switch from '~/components/Switch';
import { useToasts, TOAST_TYPES } from '~/components/Toast';
import Tooltip from '~/components/Tooltip';

import { WarningIcon } from './components/WarningIcon';

import UsersIcon from '~/assets/mdi-account-group.svg';

import { INTEGRATIONS_CONN_ERROR_MSG, JOB_PROFILE_STATUSES } from '~/constants';
import { INSTRUCTIONS } from '~/constants/instructions';
import {
  getJobProfilesFromIntegration,
  getTeamsFromIntegration,
  getUsersFromIntegration,
} from '~/services/integrations';
import { createJobProfiles, getJobProfiles } from '~/services/jobProfiles';
import { createTeams } from '~/services/teams';
import { createUsersFromIntegration } from '~/services/users';
import { getTeams } from '~/store/teams/actions';
import { COLOR_PALETTE, COLORS } from '~/styles';
import getInstructionUrl from '~/utils/getInstructionUrl';
import getUserFullName from '~/utils/getUserFullName';
import isValidEmail from '~/utils/isValidEmail';

import useBoolState from '../../hooks/useBoolState';
import Button from '../Button';
import CheckBox from '../CheckBox';
import ShowSpinnerIfLoading from '../ShowSpinnerIfLoading';

const TOOLTIP_SEVERITIES = {
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'informational',
};

const Wrapper = styled.div`
  margin-top: 12px;
`;

const UserExternalHeader = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 0.7fr 4fr 2.5fr 3fr 3fr 3fr 1.5fr;
  align-items: center;
`;

const PlaceholderWrap = styled.div`
  margin: 25px auto 100px auto;
`;

const UserExternalHeaderItem = styled.div`
  ${({ width }) =>
    width
      ? css`
          width: ${width};
          margin: 0 auto;
        `
      : ''}
`;

const UserExternalWrapper = styled.div`
  width: 99%;
  background-color: ${(props) => (props.selected ? '#e6e8ec' : '#f6f8fc')};
  ${(props) => {
    if (props.severity === TOOLTIP_SEVERITIES.ERROR) {
      return 'border: 1px solid red;';
    }
    if (props.severity === TOOLTIP_SEVERITIES.WARNING) {
      return 'border: 1px solid #FDDA0D;';
    }
    if (props.severity === TOOLTIP_SEVERITIES.INFO) {
      return 'border: 1px solid #A9A9A9;';
    }
  }}
  border-radius: 4px;
  margin-top: 8px;
  margin-bottom: 8px;
  height: 64px;
  display: grid;
  grid-template-columns: 0.7fr 4fr 2.5fr 3fr 3fr 3fr 1.5fr;
  align-content: center;
`;

const SelectAllWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const SelectedAllCheckMark = styled(CheckBox)`
  align-self: center;
  padding: 8px;
  height: 18px;
  width: 18px;
`;

const SelectedCheckMark = styled(CheckBox)`
  align-self: center;
  padding: 8px;
`;
const EmailField = styled.div`
  align-self: center;
  padding-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 180px;
`;
const NameField = styled.div`
  align-self: center;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 120px;
`;
const RoleProfileSelect = styled(SearchSelectButton)`
  width: 160px;
  height: 32px;
  align-self: center;
`;
const MemberTeamSelect = styled(SearchSelectButton)`
  width: 160px;
  align-self: center;
`;

const SelectWrapper = styled.div`
  width: 180px;
  display: flex;
  gap: 5px;
  padding: 8px;
`;

const CoachTeamSelect = styled(SearchSelectButton)`
  width: 160px;
  align-self: center;
`;

const AdminSwitchWrapper = styled.div`
  display: flex;
  padding-left: 10px;
  width: 80px;
  align-self: center;
  justify-self: center;
  justify-content: right;
`;

const AdminSwitch = styled(Switch)`
  align-self: center;
  justify-self: center;
`;

const ButtonSection = styled.div`
  display: flex;
  padding: 8px 0px 16px 18px;
  justify-content: flex-end;
`;

const messages = {
  membersImported: {
    title: t`Success`,
    content: t`The selected members have been invited`,
  },
};

const validateUserExternalFields = (i18n, user) => {
  if (!user.firstName) {
    return {
      field: 'firstName',
      message: i18n._(
        t`The user doesn't have a first name, set it on your integration system to be able to import this user in Learned`,
      ),
    };
  }
  if (!user.lastName) {
    return {
      field: 'lastName',
      message: i18n._(
        t`The user doesn't have a last name, set it on your integration system to be able to import this user in Learned`,
      ),
    };
  }
  if (!user.email) {
    return {
      field: 'email',
      message: i18n._(
        t`The user doesn't have an email address, set it on your integration system to be able to import this user in Learned`,
      ),
    };
  }
  if (!isValidEmail(user.email)) {
    return {
      field: 'email',
      message: i18n._(
        t`The user has an invalid email address, fix it on your integration system to be able to import this user in Learned`,
      ),
    };
  }
  return null;
};

const TeamsWarningTooltip = ({ title, teams }) => (
  <div>
    <Trans>{title}</Trans>
    <ul>
      {teams.map((team) => (
        <li key={team.id}>{team.name}</li>
      ))}
    </ul>
  </div>
);

function renderUserRow(
  i18n,
  userExternal,
  selectedUsersExternal,
  setSelectedUsersExternal,
  teams,
  roles,
  integration,
) {
  const isUserSelected = selectedUsersExternal
    .map((x) => x.externalId)
    .includes(userExternal.externalId);
  const selectedUserExternal = selectedUsersExternal.find(
    (x) => x.externalId === userExternal.externalId,
  );

  const teamOptions = [...map(teams, (team) => ({ label: team.name, id: team.id }))];

  const coachTeams =
    selectedUserExternal &&
    teams.filter((team) => selectedUserExternal?.coachTeams?.includes(team.id));
  const partialCoachTeams =
    selectedUserExternal &&
    teams.filter((team) => selectedUserExternal?.partialCoachTeams?.includes(team.id));

  const selectedUserMemberTeamValues =
    isUserSelected && selectedUserExternal.memberTeams ? selectedUserExternal.memberTeams : [];
  const selectedUserCoachTeamValues =
    isUserSelected && selectedUserExternal.coachTeams ? selectedUserExternal.coachTeams : [];
  const roleOptions = [...map(roles, (role) => ({ id: role.id, label: role.name }))];
  const selectedUserRoleValues =
    isUserSelected && selectedUserExternal.jobProfiles ? selectedUserExternal.jobProfiles : [];
  const selectedUserIsAdmin = isUserSelected && selectedUserExternal.isAdmin;

  const { tooltip } = userExternal;

  return (
    <Tooltip
      placement="top-start"
      disabled={!tooltip}
      tooltip={(tooltip && tooltip.message) || ''}
      key={userExternal.externalId}
    >
      <UserExternalWrapper
        selected={isUserSelected}
        severity={tooltip && tooltip.severity}
        onClick={() => {
          if ((!tooltip || !tooltip.disableItem) && !isUserSelected) {
            setSelectedUsersExternal([...selectedUsersExternal, userExternal]);
          }
        }}
      >
        <SelectedCheckMark
          checked={isUserSelected}
          onChange={() => {
            setSelectedUsersExternal(
              isUserSelected
                ? selectedUsersExternal.filter((x) => x.externalId !== userExternal.externalId)
                : [...selectedUsersExternal, userExternal],
            );
          }}
          disabled={tooltip && tooltip.disableItem}
        />
        <Tooltip tooltip={userExternal.email} maxWidth="100%">
          <EmailField>{userExternal.email}</EmailField>
        </Tooltip>
        <Tooltip tooltip={getUserFullName(userExternal)} maxWidth="100%">
          <NameField>{getUserFullName(userExternal)}</NameField>
        </Tooltip>
        <SelectWrapper>
          <RoleProfileSelect
            disabled={!isUserSelected}
            checkedList={selectedUserRoleValues}
            options={roleOptions}
            title={i18n._(t`Roles`)}
            handleChange={(e) => {
              if (isUserSelected) {
                setSelectedUsersExternal([
                  ...selectedUsersExternal.filter((x) => x.externalId !== userExternal.externalId),
                  { ...selectedUserExternal, jobProfiles: e },
                ]);
              }
            }}
            height="36px"
          />
        </SelectWrapper>
        <SelectWrapper>
          <MemberTeamSelect
            disabled={!isUserSelected}
            checkedList={selectedUserMemberTeamValues}
            options={map(teamOptions, (t) => ({ id: t.id, label: t.label }))}
            title={i18n._(t`Teams`)}
            handleChange={(e) => {
              if (isUserSelected) {
                setSelectedUsersExternal([
                  ...selectedUsersExternal.filter((x) => x.externalId !== userExternal.externalId),
                  { ...selectedUserExternal, memberTeams: e },
                ]);
              }
            }}
            height="36px"
          />
        </SelectWrapper>
        <SelectWrapper>
          {isUserSelected &&
            integration.externalSoftware === INTEGRATIONS.KOMBO &&
            (coachTeams?.length > 0 || partialCoachTeams?.length > 0) && (
              <WarningIcon
                message={
                  <div>
                    {coachTeams?.length > 0 && (
                      <TeamsWarningTooltip
                        title={i18n._(
                          t`Selected as a coach for the following teams because the user is the manager of all their members:`,
                        )}
                        teams={coachTeams}
                      />
                    )}
                    {partialCoachTeams?.length > 0 && (
                      <TeamsWarningTooltip
                        title={i18n._(
                          t`Not selected as a coach for the following teams because the user is the manager of all their members:`,
                        )}
                        teams={partialCoachTeams}
                      />
                    )}
                  </div>
                }
              />
            )}
          <CoachTeamSelect
            disabled={!isUserSelected}
            checkedList={selectedUserCoachTeamValues}
            options={map(teamOptions, (t) => ({ id: t.id, label: t.label }))}
            title={i18n._(t`Coach teams`)}
            handleChange={(e) => {
              if (isUserSelected) {
                setSelectedUsersExternal([
                  ...selectedUsersExternal.filter((x) => x.externalId !== userExternal.externalId),
                  { ...selectedUserExternal, coachTeams: e },
                ]);
              }
            }}
            height="36px"
          />
        </SelectWrapper>
        <AdminSwitchWrapper>
          <AdminSwitch
            onChange={(e) => {
              setSelectedUsersExternal([
                ...selectedUsersExternal.filter((x) => x.externalId !== userExternal.externalId),
                { ...selectedUserExternal, isAdmin: e },
              ]);
            }}
            disabled={!isUserSelected}
            checked={selectedUserIsAdmin}
          />
        </AdminSwitchWrapper>
      </UserExternalWrapper>
    </Tooltip>
  );
}

const UsersIntegrationTab = ({ onModalClose, teams = [], search, integration, invites }) => {
  const [loading, setLoading] = useState(true);
  const [usersExternal, setUsersExternal] = useState([]);
  const [selectedUsersExternal, setSelectedUsersExternal] = useState([]);
  const [allTeams, setAllTeams] = useState(teams);
  const [availableRoles, setAvailableRoles] = useState();
  const [isIntegrationError, setIsIntegrationError] = useState(false);
  const $isSelectAll = useBoolState(false);
  const { addToast } = useToasts();
  const { i18n } = useLingui();
  const dispatch = useDispatch();

  useEffect(() => {
    setUsersExternal([]);
    setSelectedUsersExternal([]);
    if (!loading) {
      setLoading(true);
    }
    const getData = async () => {
      let usersFromIntegration;
      let teamsFromIntegration = [];
      let rolesFromIntegration = [];
      let roles;

      const isIntRolesEnabled = integration.integrationModules.importRoles.enabled;
      const isIntTeamsEnabled = integration.integrationModules.importTeams.enabled;

      try {
        [teamsFromIntegration, usersFromIntegration, rolesFromIntegration, roles] =
          await Promise.all([
            isIntTeamsEnabled ? getTeamsFromIntegration(integration.id) : [],
            getUsersFromIntegration(integration.id, true),
            isIntRolesEnabled ? getJobProfilesFromIntegration(integration.id) : [],
            Object.values(await getJobProfiles({ status: JOB_PROFILE_STATUSES.ACTIVE.key })),
          ]);
      } catch (e) {
        // Do nothing
      }

      // If it is undefined then the fetching of integration data failed
      if (!usersFromIntegration) {
        setIsIntegrationError(true);
        setLoading(false);
        return;
      }

      // Combine existing roles and not created integration roles, to distinguish the ones that need to be created
      const combinedRoles = [
        ...roles,
        ...(Array.isArray(rolesFromIntegration) ? rolesFromIntegration : [])
          .filter(
            ({ id, name }) =>
              id &&
              name &&
              !roles.some(
                ({ externalId, externalSource }) =>
                  externalSource === integration.id && externalId === id.toString(),
              ),
          )
          .map(({ id, name }) => ({
            id: id.toString(),
            name,
            externalId: id.toString(),
            externalSource: integration.id,
            isNew: true,
          })),
      ];

      setAvailableRoles(sortBy(combinedRoles, ({ name }) => name.toLowerCase()));

      // Combine existing teams and not created integration teams, to distinguish the ones that need to be created
      const combinedTeams = [
        ...teams,
        ...(Array.isArray(teamsFromIntegration) ? teamsFromIntegration : [])
          .filter(
            ({ id, name }) =>
              id &&
              name &&
              !teams.some(
                ({ externalId, externalSource }) =>
                  externalSource === integration.id && externalId === id.toString(),
              ),
          )
          .map(({ id, name }) => ({
            id: id.toString(),
            name,
            externalId: id.toString(),
            externalSource: integration.id,
            isNew: true,
          })),
      ];

      setAllTeams(sortBy(combinedTeams, ({ name }) => name.toLowerCase()));

      const usersExternal = Object.values(usersFromIntegration || []).filter(
        ({ accountEnabled }) => accountEnabled,
      );
      const invitedEmails = Object.values(invites).map(({ email }) => email);

      usersExternal.map((userExt) => {
        if (userExt.email) {
          userExt.email = userExt.email.toLowerCase();
        }

        // Keep selected any role
        if (userExt.roles) {
          if (isIntRolesEnabled) {
            const userRolesIds = userExt.roles.map(({ roleId }) => roleId.toString());
            userExt.jobProfiles = combinedRoles
              .filter(({ externalId }) => userRolesIds.includes(externalId))
              .map(({ id }) => id);
          } else {
            // Clean roles if the integration setting for import roles is not enabled
            userExt.roles = [];
          }
        }

        // Keep selected any team
        if (userExt.teams) {
          if (isIntTeamsEnabled) {
            const userTeamsIds = userExt.teams.map(({ teamId }) => teamId.toString());
            userExt.memberTeams = combinedTeams
              .filter(({ externalId }) => userTeamsIds.includes(externalId))
              .map(({ id }) => id);
          } else {
            // Clean teams if the integration setting for import teams is not enabled
            userExt.teams = [];
          }
        }

        // Keep selected any coach of team
        if (userExt.coachTeams) {
          if (isIntTeamsEnabled) {
            // Partial coaches will not be autoselected, as they are not coaches of all the team members
            const userCoachTeamsIds = userExt.coachTeams
              .filter(({ isPartial }) => !isPartial)
              .map(({ teamId }) => teamId.toString());
            const userPartialCoachTeamsIds = userExt.coachTeams
              .filter(({ isPartial }) => isPartial)
              .map(({ teamId }) => teamId.toString());
            userExt.coachTeams = combinedTeams
              .filter(({ externalId }) => userCoachTeamsIds.includes(externalId))
              .map(({ id }) => id);
            userExt.partialCoachTeams = combinedTeams
              .filter(({ externalId }) => userPartialCoachTeamsIds.includes(externalId))
              .map(({ id }) => id);
          } else {
            // Clean coachTeams if the integration setting for import teams is not enabled
            userExt.coachTeams = [];
          }
        }

        // Add a warning tooltip if there are other external users with the same email as the user
        const isDuplicated =
          usersExternal.filter(({ email }) => email?.toLowerCase() === userExt.email).length > 1;
        if (isDuplicated) {
          userExt.tooltip = {
            disableItem: false,
            severity: TOOLTIP_SEVERITIES.WARNING,
            message: i18n._(
              t`There are multiple users with this email, you can only import one of them`,
            ),
          };
        }

        // Add an informational tooltip to each user that is already on the invited list
        if (invitedEmails.length && invitedEmails.includes(userExt.email)) {
          userExt.tooltip = {
            disableItem: true,
            severity: TOOLTIP_SEVERITIES.INFO,
            message: i18n._(t`A user with this email has already been invited`),
          };
        }

        // Add an error tooltip if the user has any invalid field
        const invalidField = validateUserExternalFields(i18n, userExt);
        if (invalidField) {
          userExt.tooltip = {
            disableItem: true,
            severity: TOOLTIP_SEVERITIES.ERROR,
            message: invalidField.message,
          };
        }
      });

      setUsersExternal(usersExternal);
      setLoading(false);
    };
    getData();

    // eslint-disable-next-line
  }, [integration]);

  // search logic
  let usersExternalFiltered = [...usersExternal];
  if (search) {
    usersExternalFiltered = usersExternal.filter(({ email = '' }) =>
      email.includes(search.toLowerCase()),
    );
  }

  const toggleSelectAll = (isChecked) => {
    if (isChecked) {
      // select all
      $isSelectAll.on();
      setSelectedUsersExternal(
        usersExternal.filter(({ tooltip }) => !tooltip || !tooltip.disableItem),
      );
    } else {
      // deselect all
      $isSelectAll.off();
      setSelectedUsersExternal([]);
    }
  };

  const createDataFromIntegration = async () => {
    setLoading(true);

    const newAllTeams = _.cloneDeep(allTeams);
    const newSelectedUsersExternal = _.cloneDeep(selectedUsersExternal);
    const newAvailableRoles = _.cloneDeep(availableRoles);

    const allSelectedTeamsIdsSet = new Set();
    const allSelectedRolesIdsSet = new Set();
    newSelectedUsersExternal.forEach(({ memberTeams = [], coachTeams = [], jobProfiles = [] }) => {
      // Get all the external teams that need to be created
      memberTeams.forEach((teamId) => allSelectedTeamsIdsSet.add(teamId));
      coachTeams.forEach((teamId) => allSelectedTeamsIdsSet.add(teamId));

      // Get all the external roles that need to be created
      jobProfiles.forEach((roleId) => allSelectedRolesIdsSet.add(roleId));
    });

    const allSelectedTeamsIds = Array.from(allSelectedTeamsIdsSet);
    const newTeamsToCreate = newAllTeams.filter(
      ({ id, isNew }) => isNew && allSelectedTeamsIds.includes(id),
    );

    const allSelectedRolesIds = Array.from(allSelectedRolesIdsSet);
    const newRolesToCreate = newAvailableRoles.filter(
      ({ id, isNew }) => isNew && allSelectedRolesIds.includes(id),
    );

    if (newTeamsToCreate.length) {
      // Create all the selected non-existent external teams
      const newTeams = newTeamsToCreate.map(({ id, name }) => ({
        name,
        externalId: String(id),
        externalSource: integration.id,
      }));
      const createdTeams = (await createTeams(newTeams)) || [];

      createdTeams.forEach((createdTeam) => {
        // Replace old external teams data with the newly created data for them
        const teamsIndex = newAllTeams.findIndex(
          ({ id, externalSource }) =>
            id === createdTeam.externalId && externalSource === integration.id,
        );
        if (teamsIndex >= 0) {
          newAllTeams[teamsIndex] = createdTeam;
        }

        newSelectedUsersExternal.forEach((userExt) => {
          // Replace selected users memberTeams with the newly created ids
          const memberTeamsIndex = (userExt.memberTeams || []).findIndex(
            (teamId) => createdTeam.externalId === teamId,
          );
          if (memberTeamsIndex >= 0) {
            userExt.memberTeams[memberTeamsIndex] = createdTeam.id;
          }

          // Replace selected users coachTeams with the newly created ids
          const coachTeamsIndex = (userExt.coachTeams || []).findIndex(
            (teamId) => createdTeam.externalId === teamId,
          );
          if (coachTeamsIndex >= 0) {
            userExt.coachTeams[coachTeamsIndex] = createdTeam.id;
          }
        });
      });

      setAllTeams(newAllTeams);
    }

    if (newRolesToCreate.length) {
      // Create all the selected non-existent external teams
      const newRoles = newRolesToCreate.map(({ id, name }) => ({
        name,
        description: '',
        externalId: String(id),
        externalSource: integration.id,
      }));

      const createdRoles = (await createJobProfiles(newRoles)) || [];

      createdRoles.forEach((createdRole) => {
        // Replace old external teams data with the newly created data for them
        const availableRolesIndex = newAvailableRoles.findIndex(
          ({ id, externalSource }) =>
            id === createdRole.externalId && externalSource === integration.id,
        );
        if (availableRolesIndex >= 0) {
          newAvailableRoles[availableRolesIndex] = createdRole;
        }

        // Replace selected users jobProfiles with the newly created ids
        newSelectedUsersExternal.forEach((userExt) => {
          const jobProfilesIndex = (userExt.jobProfiles || []).findIndex(
            (roleId) => createdRole.externalId === roleId,
          );
          if (jobProfilesIndex >= 0) {
            userExt.jobProfiles[jobProfilesIndex] = createdRole.id;
          }
        });
      });

      setAvailableRoles(newAvailableRoles);
    }

    // If there was extra data created then update the users state
    if (newTeamsToCreate.length || newRolesToCreate.length) {
      setSelectedUsersExternal(newSelectedUsersExternal);
    }

    await createUsersFromIntegration(
      newSelectedUsersExternal.map((userExternal) => ({
        ...userExternal,
        integrationId: integration.id,
      })),
    );

    // Dispatch store update of teams
    dispatch(getTeams());

    onModalClose();
    showToastMessage(messages.membersImported, TOAST_TYPES.INFO);
  };

  const showToastMessage = (msg, type) => {
    addToast({ title: i18n._(msg.title), subtitle: i18n._(msg.content), type });
  };

  return (
    <Wrapper>
      <LearnMore
        label={i18n._(t`Manually invited members cannot be synchronised or delete via en API.`)}
        LearnMoreLink={getInstructionUrl(INSTRUCTIONS.INVITING_MANAGING_MEMBERS)}
      />
      <ShowSpinnerIfLoading loading={loading}>
        <UserExternalHeader>
          <UserExternalHeaderItem>
            <SelectAllWrapper onClick={() => toggleSelectAll(!$isSelectAll.value)}>
              <SelectedAllCheckMark checked={$isSelectAll.value} size={24} />
            </SelectAllWrapper>
          </UserExternalHeaderItem>
          <UserExternalHeaderItem>
            <Trans>Email</Trans>
          </UserExternalHeaderItem>
          <UserExternalHeaderItem>
            <Trans>Name</Trans>
          </UserExternalHeaderItem>
          <UserExternalHeaderItem>
            <Trans>Role</Trans>
          </UserExternalHeaderItem>
          <UserExternalHeaderItem>
            <Trans>Member of team</Trans>
          </UserExternalHeaderItem>
          <UserExternalHeaderItem>
            <Trans>Coach of team</Trans>
          </UserExternalHeaderItem>
          <UserExternalHeaderItem width={100}>
            <Trans>Admin</Trans>
          </UserExternalHeaderItem>
        </UserExternalHeader>

        {!usersExternal.length && (
          <PlaceholderWrap>
            <Placeholder
              title={i18n._(t`No users available`)}
              subTitle={i18n._(
                isIntegrationError
                  ? INTEGRATIONS_CONN_ERROR_MSG
                  : t`It seems that all employees in your HR system have been added to Learned.`,
              )}
              subTitleStyles={{ ...(isIntegrationError && { color: COLORS.ACCENT_ERROR }) }}
              Icon={() => (
                <SvgIcon
                  url={UsersIcon}
                  width="50px"
                  height="50px"
                  isDefaultColor
                  defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
                />
              )}
            />
          </PlaceholderWrap>
        )}

        {!isEmpty(usersExternalFiltered) && (
          <Scrollbars style={{ height: 500 }}>
            {usersExternal &&
              Object.values(usersExternalFiltered).map((userExternal) =>
                renderUserRow(
                  i18n,
                  userExternal,
                  selectedUsersExternal,
                  setSelectedUsersExternal,
                  allTeams,
                  availableRoles,
                  integration,
                ),
              )}
          </Scrollbars>
        )}
        <ButtonSection>
          <Button
            label={i18n._(t`Invite`)}
            disabled={isEmpty(selectedUsersExternal)}
            onClick={createDataFromIntegration}
            loading={loading}
            type="primary"
          />
        </ButtonSection>
      </ShowSpinnerIfLoading>
    </Wrapper>
  );
};

export default UsersIntegrationTab;
