export enum INTEGRATIONS {
  AFAS = 'afas',
  AZURE_AD = 'azure_ad',
  KOMBO = 'kombo',
  NMBRS = 'nmbrs',
  SDWORX = 'sdworx',
}

export enum IMPLEMENTED_HRIS_TOOLS {
  AFAS = 'afas',
  AZURE_AD = 'azuread',
  ENTRA_ID = 'entraid',
  HEAVEN_HR = 'heavenhr',
  HIBOB = 'hibob',
  HRWORKS = 'hrworks',
  KENJO = 'kenjo',
  NMBRS = 'nmbrs',
  OFFICIENT = 'officient',
  PERSONIO = 'personio',
  SAGE_HR = 'sagehr',
  VISMA_PEPLE = 'peple',
  VISMA_RAET_YOUFORCE = 'youforce',
}

export const HRIS_TOOLS_NAMES: Record<string, string> = {
  [INTEGRATIONS.AZURE_AD]: 'Azure Active Directory',
  [INTEGRATIONS.KOMBO]: 'Kombo',
  [INTEGRATIONS.SDWORX]: 'SDWORX',
  [IMPLEMENTED_HRIS_TOOLS.AFAS]: 'AFAS',
  [IMPLEMENTED_HRIS_TOOLS.AZURE_AD]: 'Azure AD',
  [IMPLEMENTED_HRIS_TOOLS.ENTRA_ID]: 'Entra ID',
  [IMPLEMENTED_HRIS_TOOLS.HEAVEN_HR]: 'HeavenHR',
  [IMPLEMENTED_HRIS_TOOLS.HIBOB]: 'HiBob',
  [IMPLEMENTED_HRIS_TOOLS.HRWORKS]: 'HRworks',
  [IMPLEMENTED_HRIS_TOOLS.KENJO]: 'Kenjo',
  [IMPLEMENTED_HRIS_TOOLS.NMBRS]: 'NMBRS',
  [IMPLEMENTED_HRIS_TOOLS.OFFICIENT]: 'Officient',
  [IMPLEMENTED_HRIS_TOOLS.PERSONIO]: 'Personio',
  [IMPLEMENTED_HRIS_TOOLS.SAGE_HR]: 'Sage HR',
  [IMPLEMENTED_HRIS_TOOLS.VISMA_PEPLE]: 'Visma Peple',
  [IMPLEMENTED_HRIS_TOOLS.VISMA_RAET_YOUFORCE]: 'Visma Raet - Youforce',
};

export const HRIS_TOOLS_AVAILABLE_MODULES: Record<
  string,
  { roles: boolean; teams: boolean; coaches: boolean; deletedUsers: boolean }
> = {
  [IMPLEMENTED_HRIS_TOOLS.AFAS]: {
    roles: true,
    teams: true,
    coaches: true,
    deletedUsers: true,
  },
  [IMPLEMENTED_HRIS_TOOLS.AZURE_AD]: {
    roles: true,
    teams: true,
    coaches: true,
    deletedUsers: true,
  },
  [IMPLEMENTED_HRIS_TOOLS.ENTRA_ID]: {
    roles: true,
    teams: true,
    coaches: true,
    deletedUsers: true,
  },
  [IMPLEMENTED_HRIS_TOOLS.HEAVEN_HR]: {
    roles: true,
    teams: true,
    coaches: true,
    deletedUsers: false,
  },
  [IMPLEMENTED_HRIS_TOOLS.HIBOB]: {
    roles: true,
    teams: true,
    coaches: true,
    deletedUsers: false,
  },
  [IMPLEMENTED_HRIS_TOOLS.HRWORKS]: {
    roles: true,
    teams: true,
    coaches: true,
    deletedUsers: false,
  },
  [IMPLEMENTED_HRIS_TOOLS.KENJO]: {
    roles: true,
    teams: true,
    coaches: true,
    deletedUsers: false,
  },
  [IMPLEMENTED_HRIS_TOOLS.NMBRS]: {
    roles: true,
    teams: true,
    coaches: true,
    deletedUsers: true,
  },
  [IMPLEMENTED_HRIS_TOOLS.OFFICIENT]: {
    roles: true,
    teams: true,
    coaches: true,
    deletedUsers: false,
  },
  [IMPLEMENTED_HRIS_TOOLS.PERSONIO]: {
    roles: true,
    teams: true,
    coaches: true,
    deletedUsers: false,
  },
  [IMPLEMENTED_HRIS_TOOLS.SAGE_HR]: {
    roles: true,
    teams: true,
    coaches: true,
    deletedUsers: false,
  },
  [IMPLEMENTED_HRIS_TOOLS.VISMA_PEPLE]: {
    roles: true,
    teams: true,
    coaches: true,
    deletedUsers: false,
  },
  [IMPLEMENTED_HRIS_TOOLS.VISMA_RAET_YOUFORCE]: {
    roles: true,
    teams: true,
    coaches: true,
    deletedUsers: false,
  },
};

export enum INTEGRATION_STATUS {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}
