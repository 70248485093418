import React, { useEffect, useState } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import styled from 'styled-components';

import Button from '~/components/Button';
import { confirm } from '~/components/ConfirmDialog';
import IconMenu from '~/components/IconMenu';
import OverviewHeading from '~/components/OverviewHeading';
import { SearchField } from '~/components/Text';
import ActionsContainer from '~/components/UI/ActionsContainer';
import Divider from '~/components/UI/Divider';
import Table, { TableCol, TableRow } from '~/components/UI/Table';
import BaseLayout from '~/layouts/BaseLayout';

import { getSuperAdmins, updateSuperAdminStatus } from '~/services/superadmin';
import { findUserSuperAdmin, getSelectedUsersSuperAdmin } from '~/services/users';
import { COLOR_PALETTE } from '~/styles';
import getUserFullName from '~/utils/getUserFullName';

const ActionsContainerWrapper = styled(ActionsContainer)`
  height: 48px;
  align-items: center;
  border: unset;
  display: flex;
  flex-direction: row;
`;

const Wrapper = styled.div`
  border-radius: 6px;
  border: solid 1px ${COLOR_PALETTE.GRAY_MIDDLE};
`;

const PromoteContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
`;

const UserIDInput = styled.input`
  width: 250px;
  height: 40px;
  margin: 0 8px 0 0;
  border-radius: 6px;
  padding: 0 8px;
  border: solid 1px #c7cfe0;
  background-color: #fff;
`;

const StyledSearch = styled(SearchField)`
  height: 32px;
  width: 248px;
  & > input {
    border-radius: 6px;
    font-size: 14px;
    height: 32px;
  }
`;

const StyledTable = styled(Table)`
  background-color: white;
`;

const StyledRow = styled(TableRow)`
  padding: 0 16px;
`;

const CompanyName = styled(TableCol)`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.38;
  color: ${COLOR_PALETTE.BLACK};
`;

const CompanyText = styled(TableCol)`
  font-size: 14px;
  line-height: 1.57;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const demoteUser = async (i18n, allUsers, [allSuperAdmins, setAllSuperAdmins], userToDemote) => {
  // Find the user -> First check if an ID has been entered, if not, try email.
  let userId = userToDemote;
  if (!allUsers[userToDemote]) {
    const foundUser = Object.values(allUsers).find((x) => x.email === userToDemote);
    if (foundUser && typeof foundUser === 'object') {
      userId = foundUser.id;
    } else {
      alert('User not found...');
      return;
    }
  }

  // Grab the actual user object and use the data in our warnings
  const user = allUsers[userId];

  // Check if the user is sure about this demotion by giving them the user's name as well.
  if (
    await confirm(
      i18n,
      i18n._(t`Are you sure you want to demote ${getUserFullName(user)} to regular user?`),
    )
  ) {
    // Carry out the actual demotion
    const result = await updateSuperAdminStatus(userId, false);

    // If it succeeded, remove from the list of super admins and clear the text field.
    if (result) {
      setAllSuperAdmins(allSuperAdmins.filter((x) => x !== userId));
    } else {
      // If something failed, show a brief error message.
      alert('An error occurred... Maybe the user does not exist?');
    }
  }
};

const sortSuperAdmins = (ids = [], users = {}) =>
  sortBy(ids, (id) => (users[id] ? getUserFullName(users[id]).toLowerCase() : ''));

const SuperAdminOverview = ({ i18n }) => {
  const [superAdmins, setSuperAdmins] = useState({});
  const [userToUpdate, setUserToUpdate] = useState();
  const [allUsers, setAllUsers] = useState({});
  const [search, setSearch] = useState('');

  const fetchData = async () => {
    const fetchedSuperAdmins = await getSuperAdmins();
    const fetchedAllUsers = await getSelectedUsersSuperAdmin('all', fetchedSuperAdmins);

    setSuperAdmins(sortSuperAdmins(fetchedSuperAdmins, fetchedAllUsers));
    setAllUsers(fetchedAllUsers);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const cols = [
    { title: i18n._(t`Name`) },
    { title: i18n._(t`E-mail`), width: '266px' },
    { width: '40px' },
  ];

  const promoteUser = async () => {
    // Find user based on the email
    let user;
    try {
      user = (await findUserSuperAdmin(userToUpdate)).data.user;
    } catch (e) {
      alert('User not found...');
      return;
    }

    // If the user has an email address that doesn't end in @learned.io, check if we want to make them
    // a super admin by adding an extra warning.
    let extraWarning = '';
    if (!user.email.endsWith('@learned.io')) {
      extraWarning = '\r\nTHIS EMAIL ADDRESS IS NOT AN @learned.io EMAIL ADDRESS! ARE YOU SURE?';
    }

    // Check if the user is sure about this promotion by giving them the user's name as well.
    if (
      await confirm(
        i18n,
        `Are you sure you want to promote ${getUserFullName(user)} to super admin?` + extraWarning,
      )
    ) {
      // Carry out the actual promotion
      const result = await updateSuperAdminStatus(user.id, true);

      if (result) {
        const newAllUsers = { ...allUsers, [user.id]: user };
        setAllUsers(newAllUsers);
        setSuperAdmins(sortSuperAdmins([...superAdmins, user.id], newAllUsers));

        // Clear the text field
        setUserToUpdate('');
      } else {
        // If something failed, show a brief error message.
        alert('An error occurred... Maybe the user does not exist?');
      }
    }
  };

  const renderRow = (userId) => {
    const user = allUsers[userId];
    const menuItems = [
      {
        label: i18n._(t`Demote`),
        action: async () => demoteUser(i18n, allUsers, [superAdmins, setSuperAdmins], userId),
      },
    ];
    return user ? (
      <StyledRow key={user.id}>
        <CompanyName>{getUserFullName(user)}</CompanyName>
        <CompanyText>{user.email}</CompanyText>
        <TableCol>
          <IconMenu items={menuItems} />
        </TableCol>
      </StyledRow>
    ) : undefined;
  };

  const searchUser = (userId) => {
    const user = allUsers[userId];
    return (
      !search ||
      getUserFullName(user).toLowerCase().includes(search.toLowerCase()) ||
      user.email.toLowerCase().includes(search.toLowerCase())
    );
  };

  const handleChangeSearch = (e) => {
    setSearch(e.target.value);
  };

  return (
    <div>
      <OverviewHeading
        onBack={history.goBack}
        title={i18n._(t`Super admins`)}
        description={i18n._(t`Manage the Learned super admins`)}
      />
      <BaseLayout>
        <PromoteContainer>
          <UserIDInput
            value={userToUpdate}
            type="text"
            placeholder="email of user to promote"
            onChange={(e) => setUserToUpdate(e.target.value.trim())}
          />
          <Button
            type="primary"
            label={i18n._(t`Promote`)}
            disabled={!userToUpdate}
            onClick={promoteUser}
            styles={{ paddingLeft: 0 }}
          />
        </PromoteContainer>
        <Wrapper>
          <ActionsContainerWrapper noBottomBorder>
            <StyledSearch
              placeholder={i18n._(t`Search`)}
              value={search}
              onChange={handleChangeSearch}
            />
          </ActionsContainerWrapper>
          <Divider />
          <StyledTable
            loading={isEmpty(superAdmins)}
            cols={cols}
            items={Object.values(superAdmins).filter(searchUser)}
            renderRow={renderRow}
          />
        </Wrapper>
      </BaseLayout>
    </div>
  );
};

export default withI18n()(SuperAdminOverview);
